import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

export type Author = {
  name: string;
  url_avatar: string;
};

export type Post = {
  id: number;
  author?: Author;
  comments_count?: number;
  url_upload: string;
  name?: string;
  description?: string;
  translation?: {
    name?: string;
    description?: string;
  };
  is_company_post: string;
  show_to: string;
  upload_id?: number;
  created_at: string;
  updated_at: string;
};

type PostMeta = {
  currentPage: number;
};

export type Comment = {
  id: number;
  user_id: number;
  post_id?: number;
  message: string;
  created_at: string;
  edited: boolean;
  author: Author;
  post?: {
    name: string;
    upload: any;
  };
};

@Module({ namespaced: true })
export default class WhiteboardStore extends VuexModule {
  private _data: null | Post[] = null;
  private _personalData: null | Post[] = null;

  private _lazyLoaded: boolean[] = [];
  private _currentPage = 1 as number;
  private _lastPage: null | number = null;

  get data(): null | Post[] {
    return this._data;
  }

  get personalData(): null | Post[] {
    return this._personalData;
  }

  get lazyLoaded(): boolean[] {
    return this._lazyLoaded;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  get lastPage(): null | number {
    return this._lastPage;
  }

  @Mutation
  setLazyLoaded(data: boolean[]): void {
    this._lazyLoaded = data;
  }

  @Mutation
  setData(data: Post[]): void {
    this._data = data;
  }

  @Mutation
  clearData(): void {
    this._data = null;
    this._lazyLoaded = [];
    this._currentPage = 1;
    this._lastPage = null;
  }

  @Mutation
  setPersonalData(data: Post[]): void {
    this._personalData = data;
  }

  @Mutation
  updatePersonalData(data: Post): void {
    const personalIndex = this._personalData?.findIndex(post => post.id == data.id);

    if (this._personalData && personalIndex != undefined && personalIndex != -1) {
      this._personalData[personalIndex] = {
        id: data.id,
        url_upload: data.url_upload,
        name: data.translation?.name,
        description: data.translation?.description,
        is_company_post: data.is_company_post,
        show_to: data.show_to,
        created_at: data.created_at,
        updated_at: data.updated_at
      };
    }
  }

  @Mutation
  setCurrentPage(data: number): void {
    this._currentPage = data;
  }

  @Mutation
  setLastPage(data: number): void {
    this._lastPage = data;
  }

  @Action
  fetchAll(data: PostMeta) {
    return Api.get(`/api/apps/posts/list/1?page=${data.currentPage}`);
  }

  @Action
  fetchPersonal() {
    return Api.get("/api/apps/posts/my-posts");
  }

  @Action
  fetchById(id: number) {
    return Api.get(`/api/apps/posts/${id}`);
  }

  @Action
  fetchCommentsByPostId(id: number) {
    return Api.get(`/api/posts/${id}/comments?per_page=100`);
  }

  @Action
  fetchMyComments() {
    return Api.get(`/api/apps/posts/comments/my?per_page=100`);
  }

  @Action
  postComment(data: object) {
    return Api.post(`/api/apps/posts/comments`, data);
  }

  @Action
  patchComment(data: { id: number; body: object }) {
    return Api.patch(`/api/posts/comments/${data.id}`, data.body);
  }

  @Action
  deleteComment(id: number) {
    return Api.delete(`/api/posts/comments/${id}`);
  }

  @Action
  store(data: object) {
    return Api.post("/api/apps/posts", data);
  }

  @Action
  update(data: { id: number }) {
    return Api.patch(`/api/apps/posts/${data.id}`, data);
  }

  @Action
  delete(id: number) {
    return Api.delete(`/api/apps/posts/${id}`);
  }

  @Action
  uploadPicture(data: object) {
    return Api.post("/api/uploads/posts", data);
  }
}
