import { Action, Module, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

interface ShareArgs {
  id: number;
  data: Record<string, boolean>;
}

interface AttachArgs {
  id: number;
  data: Record<string, number>;
}

interface DeleteArgs {
  document: number;
}

@Module({ namespaced: true })
export default class DocumentsStore extends VuexModule {
  @Action
  dirs() {
    return Api.get("/api/apps/shared-documents/dirs");
  }

  @Action
  dir(id: number) {
    return Api.get(`/api/apps/shared-documents/dirs/${id}`);
  }

  @Action
  share({ id, data }: ShareArgs) {
    return Api.post(`/api/apps/shared-documents/dirs/${id}/share`, data);
  }

  @Action
  upload(data: object) {
    return Api.post("/api/uploads/shared-documents", data);
  }

  @Action
  attach({ id, data }: AttachArgs) {
    return Api.post(`/api/apps/shared-documents/dirs/${id}`, data);
  }

  @Action
  delete({ document }: DeleteArgs) {
    return Api.delete(`/api/apps/shared-documents/${document}`);
  }
}
