import Vue from "vue";

import store from "@/app/store";

type UserData = {
  user: {
    email?: string;
  };
};

export const profileMixin = Vue.mixin({
  computed: {
    userHasContactInfo() {
      const userData = store.getters["profile/data"];
      return userData.user.email && userData.personal_profile?.mobile_phone;
    }
  },

  methods: {
    hasRole(role) {
      const userData = store.getters["profile/data"];
      return userData.roles.some(r => r.slug == role);
    }
  }
});
