import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { DeviceId, DeviceInfo } from "@capacitor/device";
import Api from "@/app/modules/_global/classes/api/Api";

@Module({ namespaced: true })
export default class DeviceStore extends VuexModule {
  private _deviceId: null | DeviceId = null;
  private _deviceInfo: null | DeviceInfo = null;

  get deviceId(): null | DeviceId {
    return this._deviceId;
  }

  get deviceInfo(): null | DeviceInfo {
    return this._deviceInfo;
  }

  @Mutation
  setDeviceId(data: DeviceId): void {
    this._deviceId = data;
  }

  @Mutation
  setDeviceInfo(data: DeviceInfo): void {
    this._deviceInfo = data;
  }

  @Action
  fetchCurrent(data: string) {
    return Api.get(`/api/profile/device/${data}`);
  }

  @Action
  updateFcmTokenActive(data: { id: number }) {
    return Api.patch(`/api/profile/fcm-token-active/${data.id}`, data);
  }
}
