export const error = {
  path: "/error",
  component: () => import("@/app/layouts/ChildLayout.vue"),

  children: [
    {
      path: "api",
      name: "error",
      component: () => import("@/app/modules/_global/pages/Error.vue"),
      props: route => ({
        error: route.params.error
      })
    }
  ]
};

export const welcomeContent = {
  path: "/welcome",
  component: () => import("@/app/layouts/AuthLayout.vue"),

  beforeEnter: (to, from, next) => {
    const allowedNames = ["register", "qr-registration"];
    allowedNames.includes(from.name) ? next() : next({ name: "login" });
  },

  children: [
    {
      path: "",
      name: "welcome",
      component: () => import("@/app/modules/_global/pages/Welcome.vue"),

      meta: {
        title: "GREETING.TITLE"
      }
    }
  ]
};

export const imposeContent = {
  path: "/impose",
  component: () => import("@/app/layouts/ChildLayout.vue"),

  children: [
    {
      path: "html",
      name: "impose.html",
      component: () => import("@/app/modules/_global/pages/ImposeHtml.vue")
    }

    // impose.iframe
    // impose.form
    // impose.service
  ]
};
