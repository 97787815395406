import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class PersonalInterestsStore extends VuexModule {
  private _data: null | object = null;
  private _salutations = [];

  get data() {
    return this._data;
  }

  get salutations() {
    return this._salutations;
  }

  @Mutation
  setData(data: object): void {
    this._data = data;
  }

  @Mutation
  setSalutations(salutations: []): void {
    this._salutations = salutations;
  }

  @Action
  update(data: object) {
    return Api.patch("/api/profile", data);
  }

  @Action
  delete() {
    return Api.delete("/api/profile");
  }

  @Action
  updatePassword(data: object) {
    return Api.patch("/api/profile/update-password", data);
  }

  @Action
  updateAppDevice(data: object) {
    return Api.post("/api/profile/app-device", data);
  }

  @Action
  updateAppLanguageId(data: object) {
    return Api.patch("/api/app-language-id", data);
  }

  @Action
  uploadAvatar(data: object) {
    return Api.post("/api/uploads/avatars", data);
  }

  @Action
  attachAvatar(data: object) {
    return Api.post("/api/persons/attach-avatar", data);
  }

  @Action
  acceptTerms() {
    return Api.patch("/api/apps/accept-terms");
  }

  @Action
  receiveInvoicesViaEmail(data: Record<string, unknown>) {
    return Api.post("/api/profile/receive-via-email", data);
  }
}
