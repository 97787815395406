import { Device, DeviceId, DeviceInfo, GetLanguageCodeResult } from "@capacitor/device";

import store from "@/app/store";

export default class DeviceService {
  screenWidth: number;
  screenHeight: number;

  constructor() {
    this.screenWidth = screen.width;
    this.screenHeight = screen.height;

    this.getDeviceId().then(deviceId => {
      store.commit("device/setDeviceId", deviceId);
    });

    this.getDeviceInfo().then(deviceInfo => {
      store.commit("device/setDeviceInfo", deviceInfo);
    });
  }

  get getDeviceScreenSize(): object {
    return {
      width: this.screenWidth,
      height: this.screenHeight
    };
  }

  getDeviceId(): Promise<DeviceId> {
    return Device.getId();
  }

  getDeviceInfo(): Promise<DeviceInfo> {
    return Device.getInfo();
  }

  getLanguageCode(): Promise<GetLanguageCodeResult> {
    return Device.getLanguageCode();
  }
}
