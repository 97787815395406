import { App, AppInfo } from "@capacitor/app";
import { LocaleMessageObject } from "vue-i18n";
import i18n from "@/i18n";
import store from "@/app/store";
import vuetify from "@/plugins/vuetify";

// Import the unique bundle id
import { appId } from "@/../capacitor.config.json";

// Classes
import DeviceService from "@/app/modules/_global/classes/Device";
import Vuetify from "@/app/modules/_global/classes/Vuetify";

export default class AppService {
  static device = new DeviceService();

  static async init({ auth = false }): Promise<void> {
    const route: string = auth ? "auth/initAuth" : "auth/initApp";

    let appInfo: AppInfo;

    const deviceId = await this.device.getDeviceId();
    const deviceInfo = await this.device.getDeviceInfo();
    const deviceLanguage = await this.device.getLanguageCode();
    const appLanguage = store.getters["app/language"];

    if (deviceInfo.platform != "web") {
      store.commit("app/setAppInfo", await App.getInfo());
      appInfo = store.getters["app/appInfo"];
    }

    return new Promise((resolve, reject) => {
      store
        .dispatch(route, {
          bundle: appId,
          language: appLanguage ?? deviceLanguage.value,
          device: {
            app: appInfo?.name,
            uuid: deviceId.identifier,
            platform: deviceInfo.platform,
            model: deviceInfo.model,
            os: deviceInfo.operatingSystem,
            app_version: appInfo?.version ?? 0,
            app_build: appInfo?.build ?? 0,
            bundle: appInfo?.id ?? appId
          }
        })
        .then(({ data }) => {
          store.commit("app/setData", data.app);
          store.commit("profile/setSalutations", data.salutations ?? []);

          if (auth) {
            store.commit("profile/setData", data.user_data);
            store.commit("tags/setData", data.user_data.tags);
            store.commit("tags/setPersonInterestsData", data.user_data.person.interests);
            store.commit("news/setData", data.user_data.notifications);
            store.commit("app/setBuildingComplexData", data.building_complex);

            // TODO: remove after merging new functionality to production
            store.commit(
              "tags/setData",
              data.user_data.tags.map((tag: { id: number }) => tag.id)
            );
          }

          const allowedLanguages = data.app.languages.map((el: { locale: string }) => el.locale);
          const defaultLanguage = data.app.default_language.locale;

          let locale: string;

          if (appLanguage != null && allowedLanguages.includes(appLanguage)) {
            locale = appLanguage;
          } else if (allowedLanguages.includes(deviceLanguage.value)) {
            locale = deviceLanguage.value;
          } else {
            locale = defaultLanguage;
          }

          this.setLocale(locale, data.translations);

          new Vuetify(data.app.layout);

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static setLocale(locale: string, data: LocaleMessageObject): void {
    i18n.setLocaleMessage(locale, data);
    i18n.locale = locale;
    vuetify.framework.lang.current = locale;

    store.commit("app/setLanguage", locale);
  }
}
