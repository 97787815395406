
import { SplashScreen } from "@capacitor/splash-screen";
import { App } from "@capacitor/app";
import { Component, Vue } from "vue-property-decorator";

import Connection from "@/app/modules/_global/classes/Connection";
import Push from "@/app/modules/_global/classes/Push";

const NetworkConnection = new Connection();

@Component({
  components: {
    Disclaimer: () => import("@/app/layouts/components/DisclaimerDialog.vue"),
    NavDrawer: () => import("@/app/layouts/components/NavigationDrawer.vue"),
    BottomNav: () => import("@/app/layouts/components/BottomNavigation.vue"),
    Snackbar: () => import("@/app/modules/_global/components/Snackbar.vue"),
    Carousel: () => import("@/app/modules/_global/components/Carousel.vue")
  }
})
export default class MainLayout extends Vue {
  navDrawer: boolean = false as boolean;
  termsAccepted: boolean;

  constructor() {
    super();

    this.termsAccepted = this.userData?.user?.terms_accepted_at == null;
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  created() {
    SplashScreen.hide();

    App.addListener("appStateChange", async state => {
      if (state.isActive) {
        if (this.deviceInfo.platform != "web") {
          await Push.clearBadge();
        }
      }
    });

    if (this.deviceInfo.platform != "web") {
      Push.registerNotifications().then(() => {
        Push.addListeners(this.deviceInfo);
      });
    }
  }

  beforeDestroy() {
    App.removeAllListeners();
    NetworkConnection.removeListener();

    if (this.deviceInfo.platform != "web") {
      Push.removeAllListeners();
    }
  }
}
