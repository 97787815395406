import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

type Reservation = {
  id: number;
};

type Task = {
  id: number;
};

export type Event = {
  id: number;
  tag: string;
  status: string;
  status_id: number;
  type: string;
  name: string;
  start: string;
  end: string;
  from: string;
  to: string;
  location: string;
  access_key?: string;
  employee?: string;
};

export type Params = {
  "between[from]": string;
  "between[to]": string;
  page: number;
};

@Module({ namespaced: true })
export default class CalendarStore extends VuexModule {
  private _reservations: Reservation[] = [];
  private _tasks: Task[] = [];

  get reservations(): Reservation[] {
    return this._reservations;
  }

  get tasks(): Task[] {
    return this._tasks;
  }

  @Mutation
  setReservations(data: Reservation[]): void {
    this._reservations = data;
  }

  @Mutation
  setTasks(data: Task[]): void {
    this._tasks = data;
  }

  @Action
  fetchReservations(params: Params) {
    return Api.get("/api/apps/booking/my-reservations", params);
  }

  @Action
  fetchTasks(params: Params) {
    return Api.get("/api/tasks/apps", params);
  }

  @Action
  cancelReservation(data: { id: number; body: object }) {
    return Api.patch(`/api/apps/booking/reservation/cancel/${data.id}`, data.body);
  }
}
