
import { SplashScreen } from "@capacitor/splash-screen";
import { Component, Vue } from "vue-property-decorator";
import { CallNumber } from "@ionic-native/call-number";

import App from "@/app/modules/_global/classes/App";
import ConnectionService from "@/app/modules/_global/classes/Connection";

const NetworkConnection = new ConnectionService();

@Component({
  components: {
    Snackbar: () => import("@/app/modules/_global/components/Snackbar.vue")
  }
})
export default class AuthLayout extends Vue {
  devModeClicks = 0;
  devModeTimeout?: NodeJS.Timeout;

  emergencyDialog: boolean = false as boolean;

  get toolbarTitle() {
    return this.$t(this.$route.meta?.title);
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appInfo() {
    return this.$store.getters["app/appInfo"];
  }

  get loginScreenImage() {
    if (this.appData.login_screen_image == null) return null;

    let url = process.env.VUE_APP_CORE_URL;

    if (this.appMode == "Dev") url = process.env.VUE_APP_CORE_DEV_URL;
    if (this.appMode == "Test") url = process.env.VUE_APP_CORE_TEST_URL;

    return `${url}/storage/${this.appData.login_screen_image}`;
  }

  get appLanguages() {
    return this.appData.languages;
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  set appLanguage(locale) {
    this.$store.dispatch("app/changeLanguage", locale).then(({ data }) => {
      App.init({ auth: false });
      App.setLocale(locale, data);
    });
  }

  get envAppMode() {
    return process.env.VUE_APP_MODE as string;
  }

  get appMode() {
    return this.$store.getters["app/mode"];
  }

  set appMode(mode: string) {
    if (mode == "Prod") this.resetDevMode();
    this.$store.commit("app/setMode", mode);

    App.init({ auth: false });
  }

  created() {
    SplashScreen.hide();
  }

  beforeDestroy() {
    NetworkConnection.removeListener();
  }

  enterDevMode() {
    if (this.devModeClicks < 10) {
      this.devModeClicks++;

      if (this.devModeTimeout != undefined) {
        clearTimeout(this.devModeTimeout);
      }

      this.devModeTimeout = setTimeout(() => {
        if (this.devModeClicks == 10) {
          this.appMode = "Dev";
        }

        this.resetDevMode();
      }, 1000);
    }
  }

  resetDevMode() {
    this.devModeClicks = 0;
    this.devModeTimeout = undefined;
  }

  bottomNavigationHas(item: string): boolean {
    return this.appData.bottom_navigation?.includes(item);
  }

  callMedicall() {
    CallNumber.callNumber("0848111811", true)
      .then(res => console.log("Launched dialer!", res))
      .catch(err => console.log("Error launching dialer", err));
  }
}
