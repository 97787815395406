import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

@Module({ namespaced: true })
export default class AuthStore extends VuexModule {
  private apiToken: null | string = localStorage.getItem("api_token") || null;

  get hasApiToken(): boolean {
    return this.apiToken != null;
  }

  @Mutation
  setApiToken(apiToken: string): void {
    this.apiToken = apiToken;
    localStorage.setItem("api_token", apiToken);
  }

  @Mutation
  destroyApiToken(): void {
    this.apiToken = null;
    localStorage.removeItem("api_token");
  }

  @Mutation
  saveCredentials({ login, password }): void {
    localStorage.setItem("login", login);
    localStorage.setItem("password", password);
  }

  @Mutation
  destroyCredentials() {
    localStorage.removeItem("login");
    localStorage.removeItem("password");
  }

  @Action
  initApp(data: object) {
    return Api.post("/api/apps/init", data);
  }

  @Action
  initAuth(data: object) {
    return Api.post("/api/apps/init-auth", data);
  }

  // @Action
  // fetchApiToken(data: object) {
  //   return Api.post("/api/apps/init-auth", data).then(({ data }) => {
  //     this.context.commit("setApiToken", data.token);
  //   });
  // }

  @Action
  login(data: object) {
    return Api.post("/api/auth/user/app-login", data);
  }

  @Action
  logout(data: object) {
    return Api.post("/api/auth/user/logout", data);
  }

  @Action
  forgotPassword(data: object) {
    return Api.post("/api/password/email", data);
  }

  @Action
  register(data: object) {
    return Api.post("/api/auth/user/register", data);
  }

  @Action
  qrValidate(data: object) {
    return Api.post("/api/apps/qr-auth/validate", data);
  }

  @Action
  qrRegister(data: object) {
    return Api.post("/api/apps/qr-auth/registration", data);
  }
}
