import { PushNotifications } from "@capacitor/push-notifications";
import { DeviceId, DeviceInfo } from "@capacitor/device";
import { appId } from "@/../capacitor.config.json";
import { Badge } from "@capawesome/capacitor-badge";
import router from "@/app/router";
import store from "@/app/store";
import moment from "moment";
import { AppInfo } from "@capacitor/app";

export default class Push {
  static registerNotifications = async () => {
    let permissionsStatus = await PushNotifications.checkPermissions();

    if (permissionsStatus.receive == "prompt") {
      permissionsStatus = await PushNotifications.requestPermissions();
    }

    if (permissionsStatus.receive != "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  };

  static addListeners = async (deviceInfo: DeviceInfo) => {
    const appInfo = store.getters["app/appInfo"] as AppInfo;
    const deviceId = store.getters["device/deviceId"] as DeviceId;

    if (appInfo.id != appId) appInfo.id = appId;

    await PushNotifications.addListener("registration", token => {
      console.info("Registration token: , " + token.value);

      store.dispatch("profile/updateAppDevice", {
        appDevice: {
          app: appInfo.name,
          uuid: deviceId.identifier,
          platform: deviceInfo.platform,
          model: deviceInfo.model,
          os: deviceInfo.operatingSystem,
          app_version: appInfo.version,
          app_build: appInfo.build,
          bundle: appInfo.id,
          fcm_token: token.value
        }
      });
    });

    await PushNotifications.addListener("registrationError", err => {
      console.error("Registration error: ", err.error);
    });

    // The action, when the person gets the push during the app is open
    await PushNotifications.addListener("pushNotificationReceived", notification => {
      console.log("Push notification received: ", notification);

      if (
        notification.data.route == "booking" &&
        router.currentRoute.name == "booking.reservations.edit"
      ) {
        router.replace({
          name: "booking.reservations.edit",
          params: { id: notification.data.id },
          query: { push_received_at: moment().toISOString() }
        });
      }
    });

    // The action, when the person clicks on the push
    await PushNotifications.addListener("pushNotificationActionPerformed", notification => {
      console.log(
        "Push notification action performed",
        notification.actionId,
        notification.inputValue
      );

      if (notification.notification.data.route == "booking") {
        router.push({
          name: "booking.reservations.edit",
          params: { id: notification.notification.data.id }
        });
      }

      // TODO: Add news 'data' to the core controller
      else {
        store.dispatch("news/fetch").then(({ data }) => {
          store.commit("news/setData", data.data);

          if (router.currentRoute.name != "news") {
            router.push({ name: "news", params: { from: "push" } });
          }
        });
      }
    });
  };

  static getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log("Delivered notifications: ", notificationList);
  };

  static async removeAllListeners() {
    await PushNotifications.removeAllListeners();
  }

  static async clearBadge() {
    await PushNotifications.removeAllDeliveredNotifications();
    await Badge.clear();
  }

  private static async _setBadge() {
    await new Promise<void>(resolve => {
      PushNotifications.getDeliveredNotifications().then(list => {
        list.notifications.length ? Badge.set({ count: list.notifications.length }) : Badge.clear();
        resolve();
      });
    });
  }
}
