import { Route } from "vue-router/types/router";

export default {
  path: "documents",
  component: () => import("@/app/layouts/FixedContentLayout.vue"),

  children: [
    {
      path: "",
      name: "my-documents",
      component: () => import("@/app/modules/documents/pages/MyDocuments.vue")
    },

    {
      path: ":category([0-9]*)",
      name: "my-uploads",
      component: () => import("@/app/modules/documents/pages/MyUploads.vue"),
      props: (route: Route) => Object.create({ category: +route.params.category })
    },

    {
      path: ":tile([0-9]*)/:id([0-9]*)",
      name: "custom-documents",
      component: () => import("@/app/modules/documents/components/RecursiveDocumentsCategory.vue"),
      props: true
    }
  ]
};
