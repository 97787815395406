import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

export type Reservation = {
  id: number;
  type_id: number;
  resource: Record<string, any>;
  resource_name: string;
  building_complex_city: string;
  building_name: string;
  date_from: string;
  date_to: string;
  time_from: string;
  time_to: string;
  info: string;
  access_key?: string;
  number_of_visitors: number;
  status: {
    id: number;
    name: string;
    color: string;
  };
};

export type AvailableSlotData = {
  date: string;
  slots: AvailableSlot[];
};

export type AvailableSlot = {
  start: string;
  end: string;
};

export enum Status {
  New = 1,
  Occupied = 2,
  Cancelled = 3,
  Completed = 4
}

@Module({ namespaced: true })
export default class NewsStore extends VuexModule {
  private _reservations: Reservation[] = [];
  private _upcoming = true;

  get reservations(): Reservation[] {
    return this._reservations;
  }

  get upcoming(): boolean {
    return this._upcoming;
  }

  @Mutation
  setReservations(data: Reservation[]): void {
    this._reservations = data;
  }

  @Mutation
  setUpcoming(filter: boolean): void {
    this._upcoming = filter;
  }

  @Action
  fetchResourceSchedule(data: { id: number; date_from: string }) {
    return Api.get(`/api/booking/schedule/resource/${data.id}?date_from=${data.date_from}`);
  }

  @Action
  fetchMyReservations() {
    const query = "per_page=100&sort_by=time_from&order_by=asc";
    return Api.get(`/api/apps/booking/my-reservations?${query}`);
  }

  @Action
  fetchReservation(data: { reservation_id: number; person_id: number }) {
    return Api.get(
      `/api/apps/booking/reservation/${data.reservation_id}?person_id=${data.person_id}`
    );
  }

  @Action
  fetchAvailableSlots(data: { id: number; params: object }) {
    return Api.get(`/api/apps/booking/resource/${data.id}/available-slots`, data.params);
  }

  @Action
  storeReservation(data: object) {
    return Api.post("/api/apps/booking/reservation", data);
  }

  @Action
  updateReservation(data: { id: number; body: object }) {
    return Api.patch(`/api/apps/booking/reservation/${data.id}`, data.body);
  }

  @Action
  cancelReservation(id: number) {
    return Api.patch(`/api/apps/booking/reservation/cancel/${id}`);
  }
}
