import Vue from "vue";

import vuetify from "@/plugins/vuetify";
import router from "@/app/router";
import store from "@/app/store";
import i18n from "@/i18n";

// Point of entry
import App from "@/app/layouts/AppLayout.vue";

// Mixins
import { profileMixin } from "@/mixins";

// https://capacitorjs.com/docs/web/pwa-elements
import { defineCustomElements } from "@ionic/pwa-elements/loader";

Vue.config.productionTip = false;

new Vue({
  mixins: [profileMixin],
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");

defineCustomElements(window);
