import { PluginListenerHandle } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import { ConnectionStatus, Network } from "@capacitor/network";

export default class ConnectionService {
  private handler: null | PluginListenerHandle = null;

  constructor() {
    this.addNetworkListener();
    this.networkStatus().then(status => this.handleStatus(status));
  }

  async addNetworkListener() {
    await Network.addListener("networkStatusChange", status => {
      this.handleStatus(status);
    });
  }

  async networkStatus(): Promise<ConnectionStatus> {
    return await Network.getStatus();
  }

  private handleStatus(status: ConnectionStatus) {
    if (!status.connected) this.showAlert();
  }

  private async showAlert() {
    await Dialog.alert({
      title: "No Internet",
      message: "Please check your Internet connection"
    });
  }

  async removeListener(): Promise<void> {
    await this.handler?.remove();
  }
}
