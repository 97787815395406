import { Action, Module, Mutation, VuexModule, config } from "vuex-module-decorators";
import Api from "@/app/modules/_global/classes/api/Api";

config.rawError = true;

export type Tag = {
  id: number;
  name: string;
};

export type Interest = {
  id: number;
  interest: string;
};

@Module({ namespaced: true })
export default class PersonalInterestsStore extends VuexModule {
  private _data: Tag[] = [];
  private _personInterestsData: Interest[] = [];

  get data(): Tag[] {
    return this._data;
  }

  get personInterestsData(): Interest[] {
    return this._personInterestsData;
  }

  @Mutation
  setData(data: []): void {
    this._data = data;
  }

  @Mutation
  setPersonInterestsData(data: []): void {
    this._personInterestsData = data;
  }

  @Action
  fetch() {
    const query = "per_page=100&sort_by=name";
    return Api.get(`/api/tags?${query}`);
  }

  @Action
  userTags() {
    return Api.get("/api/users/tags");
  }

  @Action
  update(data: object) {
    return Api.patch("/api/users/tags", data);
  }

  @Action
  updateUserTag(id: number) {
    return Api.patch(`/api/apps/tags/${id}`);
  }

  @Action
  getPersonInterests() {
    return Api.get("/api/apps/person-interests");
  }

  @Action
  storePersonInterest(data: object) {
    return Api.post("/api/apps/person-interests", data);
  }

  @Action
  removePersonInterest(id: number) {
    return Api.delete(`/api/apps/person-interests/${id}`);
  }
}
