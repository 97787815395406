export default {
  path: "posts",
  component: () => import("@/app/layouts/ContentLayout.vue"),

  children: [
    {
      path: "",
      name: "posts",
      component: () => import("@/app/modules/whiteboard/pages/Posts.vue")
    },

    {
      path: "my-posts",
      name: "posts.my-posts",
      component: () => import("@/app/modules/whiteboard/pages/MyPosts.vue")
    },

    {
      path: "my-posts/:id([0-9]*)/edit",
      name: "posts.my-posts.edit",
      component: () => import("@/app/modules/whiteboard/pages/EditPost.vue"),
      props: route => ({
        postId: Number(route.params.id)
      })
    },

    {
      path: "my-comments",
      name: "posts.my-comments",
      component: () => import("@/app/modules/whiteboard/pages/MyComments.vue")
    },

    {
      path: "create",
      name: "posts.create",
      component: () => import("@/app/modules/whiteboard/pages/CreatePost.vue")
    },

    {
      path: ":id([0-9]*)",
      name: "posts.show",
      component: () => import("@/app/modules/whiteboard/pages/Post.vue"),
      props: route => ({
        postId: Number(route.params.id)
      })
    }
  ]
};
